import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import MetaWp from "../components/meta-wp"
import Header from "../components/header"
import Heading from "../components/heading"
import PostListing from "../components/post-listing"

const PostTemplate = ({ data }) => {
  const { wp, metaWp, allPosts } = data
  const { id, name } = wp

  return (
    <React.Fragment>
      <MetaWp wp={metaWp} />

      <Header />

      <h1 className="is-vhidden">{name}</h1>

      <Heading
        data={{
          published: true,
          title: `News Archive<br><em>${name}</em>`,
          textAlign: `left`,
        }}
      />

      <PostListing
        data={{
          published: true,
          limit: `all`,
          filterCategory: [{ termId: id }],
          cta: [
            {
              title: `All news`,
              url: `/news`,
            },
          ],
        }}
        allPosts={allPosts}
      />
    </React.Fragment>
  )
}

PostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default PostTemplate

export const pageQuery = graphql`
  query($id: String!) {
    ...MetaWpPost

    ...AllPostsFragment

    wp: wordpressCategory(id: { eq: $id }) {
      name
      slug
      id: wordpress_id
    }
  }
`
